import React from 'react'
import "../stylesheets/Resume.css"
import PDFViewer from './PDFViewer'

export default function ResumePage() {

  return (
    <>
      <div className='resume-container'>
        <h1 className='resume-title'>Resume</h1>
        <PDFViewer/>
      </div>
      <footer>
        <p>Copyright © Kaius Vanajas 2023-2024</p>
      </footer>
    </>
  )
}
