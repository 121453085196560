import React from 'react'
import omakuva from "../images/omakuva_uusi.png"
import about_me_image from "../images/about_me_image.png"
import { Link } from 'react-router-dom'
import { FaLinkedin, FaSquareGithub } from 'react-icons/fa6';

export default function HomePage() {
  return (
    <>
      <section className='home-container'>
        <div>
          <h2>Hello, I'm</h2>
          <h1>Kaius Vanajas</h1>
          {/* <h3 className='job-title'>Web Design Apprentice and Dreamer</h3> */}
          <h3 className='job-title'>Solving Problems With You To<br/> <span className='yellow-text'>Reach Goals</span></h3>
          {/* <h3 className='job-title'>Every day learning something new to create<br/> something great</h3> */}
          <div>
            <Link to="/resume" className='btn-resume'>Resume</Link>
            <Link to="/projects" className='btn-projects'>Projects</Link>
          </div>
        </div>
        <img className="profile-picture" src={omakuva} alt="Kaius Vanajas"/>
      </section>

      <section className='about-container'>
          <div>
            <img className='about-me-image' src={about_me_image} alt='A man relaxing in a bath tub'/>
          </div>
          <div className='about-text'>
            <p className="yellow-text">About me</p>
            <h3>A 30-years-old <span className='yellow-text'>career changer</span> from Helsinki</h3>
            <h4>I am a <span className='yellow-text'>last year student</span> in Laurea University of Applied Sciences, majoring in Bachelor's Degree Programme in <span className='yellow-text'>Business Information Technology</span>. 
                <br/><br/> Interested Web Development, Mobile App Development, Cloud Services and Web Design. Check out my <span className='yellow-text'>LinkedIn</span> for more information and <span className='yellow-text'>Github</span> as well.</h4>
                <div className='social-container'>
                  <a href="https://www.linkedin.com/in/kaius-vanajas/" target="_blank" rel="noreferrer"  className='linkedin'>Linked<FaLinkedin/></a>
                  <a href="https://github.com/Kakezu" target="_blank" rel="noreferrer" className='github'>Github<FaSquareGithub/></a>
                </div>
          </div>
      </section>
      <footer>
        <p>Copyright © Kaius Vanajas 2023-2024</p>
      </footer>
    </>
  )
}

// 740px x 700px tulee rajat vastaan ensimmäistä kertaa, mutta toimii edelleen.
//