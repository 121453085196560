import './App.css';
import HomePage from './components/HomePage';
import NavBar from './components/NavBar';
import ProjectsPage from "./components/ProjectsPage"
import ResumePage from "./components/ResumePage"
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

function App() {
  return (
    <Router>
      <NavBar/>
      <Routes>
        <Route path="/" element={<HomePage/>} />
        <Route path="/projects" element={<ProjectsPage/>} />
        <Route path="/resume" element={<ResumePage/>} />
      </Routes>
    </Router>
  )
}

export default App;
