import React from 'react'
import { Link } from 'react-router-dom'
import "../stylesheets/Navbar.css"

export default function NavBar() {

  return (
    <header className='header'>
      <nav className='navbar'>
        <Link to="/" className='nav-logo'>Kaius V.</Link>
        <ul className='nav-container'>

          <li className='nav-item'>
            <Link className='nav-link' to="/">Home</Link>
          </li>

          <li className='nav-item'>
            <Link className='nav-link' to="/resume">Resume</Link>
          </li>

          <li className='nav-item'>
            <Link className='nav-link' to="/projects">Projects</Link>
          </li>

        </ul>
      </nav>
    </header>
  )
}
