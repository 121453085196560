import React from 'react'
import "../stylesheets/Projects.css"
import project1 from "../images/project1.png"
import project2 from "../images/project2.png"
import project3 from "../images/project3.png"

export default function ProjectsPage() {
  return (
    <>
      <header>
        <h1 className='projects-title'>Projects</h1>
      </header>
      <section className='project1-container'>
          <div className='project1-desc'>
            <h2>Thesis - Nokia Design Sprint Challenge</h2>
            <p>For our thesis, we ideated a concept of Nokia Network Boost, 
              a web application that allows users to order a network with specific attributes through Network-as-Code. The client (Nokia) and teachers were impressed by our concept and resulted in the best grade.<br/><br/></p>
              <a className="link-story" href="https://www.canva.com/design/DAFzBfhRaC8/ohwLdbdtvpkEMrnDJOd-eA/view" target='_blank' rel='noreferrer'>Link to our Customer Story</a>
              <br/><br/>
              <a className="link-story" href="https://www.theseus.fi/handle/10024/813079" target='_blank' rel='noreferrer'>Link to our Thesis</a>
          </div>
          <img className="project1-image"src={project1} alt='kakkaa' />
      </section>
      
      <section className='project1-container'>
          <div className='project1-desc'>
            <h2>Digital service for finding internships</h2>
            <p>The purpose of the project was to find out if users are satisfied with the current services for finding internship positions and if there is a need for a new service.
              In addition to this, we wanted to find out thoroughly what the potential user of the service is like, what is important to them, and what are their core needs.<br/><br/>
              This UI prototype was based on our own user research and was created with Canva.</p>
          </div>
          <img className="project1-image"src={project2} alt='kakkaa' />
      </section>

      <section className='project1-container'>
          <div className='project1-desc'>
            <h2>Digital application for Gym-goers</h2>
            <p>The purpose of this project was to put what we have learned in Ionic framework to use. We designed wireframe from scratch in Figma and made the application seem feasible for possible gym-goers.</p>
            <a className="link-story" href="https://github.com/Kakezu/labwork-6" target='_blank' rel='noreferrer'>Link to the project in Github</a>
          </div>
          <img className="project1-image"src={project3} alt='kakkaa' />
      </section>
      <footer>
        <p>Copyright © Kaius Vanajas 2023-2024</p>
      </footer>
    </>
  )
}
