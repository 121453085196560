import React from "react";
import resumepdf from "../assets/my_resume.pdf"

const PDFViewer = () => {
 return (
 <div>
 <iframe title="kakka" src={resumepdf} width={810} height={750}/>
 </div>
 );
};
export default PDFViewer;